<template>
  <div style="overflow-x: auto">
    <table class="table table-bordered app-box-shadow">
      <thead>
      <tr>
        <th v-if="selectable" class="border-top-0"></th>
        <th v-for="(th, index) in headerList" :key="index" scope="col" class="border-top-0">
          <div class="d-flex justify-content-between">
            <div>
              {{ th['name'] }}
            </div>
            <div>
              <div v-if="th['filter']">
                <b-icon
                    v-if="th['order'] === 'ASC'"
                    v-on:click="filterOrderBy(th['index'], th['order'], index)"
                    icon="caret-down-fill"
                    font-scale="1.5"
                    variant="info"
                    class="app-box-shadow-button filter-icon p-1 ml-2"
                ></b-icon>
                <b-icon
                    v-else-if="th['order'] === 'DESC'"
                    v-on:click="filterOrderBy(th['index'], th['order'], index)"
                    icon="caret-up-fill"
                    font-scale="1.5"
                    variant="success"
                    class="app-box-shadow-button filter-icon p-1 ml-2"
                ></b-icon>
                <b-icon
                    v-else
                    v-on:click="filterOrderBy(th['index'], th['order'], index)"
                    icon="caret-down-fill"
                    font-scale="1.5"
                    variant="secondary"
                    class="app-box-shadow-button filter-icon p-1 ml-2"
                ></b-icon>
              </div>
            </div>
          </div>
        </th>
        <th v-if="isAction" class="text-center border-top-0 border-right-0" style="width: 345px" scope="col"># Actions
        </th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="data in datas" :key="data.key">
        <td v-if="selectable">
          <input class="" type="checkbox" :id="data.key" @change="selectableElem" :value="data.id"
                 :checked="handleChecked(data.id)">
        </td>
        <td :data-label="headerList[index]['name']" :class="(th['index'] === 'name') ? 'small-cell' : (th['index'] === 'id') ? 'very-small-cell' : ''"
            :style="{textAlign: th['align']}" v-for="(th, index) in headerList" :key="index">
          <span v-if="th['date']">{{ data[th['index']] | moment('DD/MM/Y') }}</span>
          <!-- ROLES USER -->
          <span v-else-if="th['index'] === 'roles'">
            <div class="text-center" v-if="data.roles.includes('ROLE_SHOP')">
              <b-badge variant="info">Commerce</b-badge>
            </div>
            <div class="text-center" v-else-if="data.roles.includes('ROLE_ADMIN')">
              <b-badge variant="danger">Admin</b-badge>
            </div>
            <div class="text-center" v-else-if="data.roles.includes('ROLE_MARKETING')">
              <b-badge variant="success">Marketing</b-badge>
            </div>
          </span>
          <span v-else-if="th['index'].includes('code')">
            <span :style="{ backgroundColor: data[th['index']] }" class="span-color">{{ data[th['index']] }}</span>
          </span>
          <!-- CONFIG SHOP -->
          <span v-else-if="th['index'] === 'config-shop-double-entry'">
              <div>{{ (data.doubleSaleEntry) ? 'oui' : 'non' }}</div>
          </span>
          <span v-else-if="th['index'] === 'config-shop-is-month'">
              <div>{{ (data.isMonth) ? 'oui' : 'non' }}</div>
          </span>
          <!-- SWITCH -->
          <span v-else-if="th['switch']">
            <b-form-checkbox
                v-if="$store.getters.getCurrentUser.id !== data['id']"
                v-model="data[th['index']]"
                name="check-button"
                switch
                v-on:change="eventChangeSwitch(data[th['index']], th['index'], data['id'])">
            </b-form-checkbox>
          </span>
          <!-- LIST SHOP -->
          <span v-else-if="th['list']">
              <b-badge class="p-2 ml-2" v-for="(shop, index) in data[th['index']]" :key="index" variant="info">{{ shop.name }}</b-badge>
          </span>
          <!-- ENTITY -->
          <span v-else-if="th['index'].includes(entityName)">
            {{ (data[th['index']]) ? data[th['index']].name : 'Null' }}
          </span>
          <!-- CATEGORIES -->
          <span v-else-if="th['index'].includes('childs')">
            {{ (data[th['index']]) ? data[th['index']].length : '' }}
          </span>
          <span v-else-if="th['index'].includes('parent')">
            {{ (data[th['index']]) ? data[th['index']].name : '' }}
          </span>
          <span v-else>
            {{ data[th['index']] }}
          </span>
        </td>
        <td data-label="Actions" class="border-right-0" style="width: 30%" v-if="isAction">
          <div class="d-md-flex justify-content-md-center">
            <div>
              <router-link
                  v-for="(action, index) in actionList"
                  :key="index"
                  class="btn flex-wrap ml-2 mt-1 mb-1"
                  v-bind:class="action['cssClass']"
                  :to="{ name: action['routeName'], params: (action['params']) ? {'id' : (entityName === 'dashboard') ? data.shop['id'] : data['id'] } : null }">
                {{ action['linkName'] }}
              </router-link>
            </div>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import $ from 'jquery'

export default {
  props: {
    headerList: Array,
    isAction: Boolean,
    actionList: Array,
    selectable: Boolean,
    listRender: Array,
    datas: Array,
    entityName: String
  },
  methods: {
    /**
     * Emit filter for parent !
     *
     * @param thIndex
     * @param thOrder
     * @param index
     */
    filterOrderBy(thIndex, thOrder, index) {
      // Set order on headerList
      if (!thOrder) {
        this.headerList[index].order = 'DESC'
      } else if (thOrder === 'ASC') {
        this.headerList[index].order = 'DESC'
      } else {
        this.headerList[index].order = 'ASC'
      }

      // Reset all order from headerList
      this.headerList.forEach((value, indexForeach) => {
        if (index !== indexForeach) {
          value['order'] = ''
        }
      })

      this.$emit('ordered', {index: thIndex, order: this.headerList[index].order})
    },
    /**
     * Event on change switch
     *
     * @param index
     * @param thIndex
     * @param id
     */
    eventChangeSwitch(index, thIndex, id) {
      this.$emit('switch', { value: index, property: thIndex, entityName: this.entityName, id: id })
    },
    /**
     * Emit selectable elem from parent
     */
    selectableElem(event) {
      let model = {
        checked: event.target.checked,
        id: Number(event.target.defaultValue),
        countElemChecked: $("[type='checkbox']:checked").length
      }

      this.$emit('selectElem', model)
    },
    /**
     * Handle check
     *
     * @param id
     * @returns {boolean}
     *
     * !!! WARNING !!! it's only for shop for now / TODO : refacto !
     */
    handleChecked(id) {
      if (this.listRender.length > 0) {
        let index = this.listRender.findIndex(obj => obj.shop.id === id)

        if (index !== -1)
          return this.listRender[index].shop.id === id
      } else {
        return false
      }
    }
  },
}
</script>
